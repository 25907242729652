import React, { useState, useEffect } from "react";
import {
  getChatMedia,
  getChatLinks,
} from "../../../../../../../crud/chats.crud";
import { ImageList, ImageListItem } from "@material-ui/core";
import styled from "styled-components";
import { ImgComponent } from "../../../../../Sales/helpers/ImgComponent";
import { extractLinks } from "../../../../helpers/extractLinks";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Message } from "../../../Messages/components/Message/Message";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Portlet } from "../../../../../../../partials/content/Portlet";
import { getImg } from "../../../../../../../crud/media.crud";

const TYPE = {
  MEDIA: 1,
  DOCS: 2,
  LINKS: 3,
};

const extension = {
  [TYPE.MEDIA]: ["jpg", "jpeg", "png", "bmp", "gif", "heic"],
  [TYPE.DOCS]: [
    "doc",
    "dock",
    "docx",
    "xls",
    "xlsx",
    "zip",
    "pdf",
    "txt",
    "scv",
  ],
};

function StandardImageList({ itemData, onDownload }) {
  const [menu, setMenu] = useState(false);

  return itemData.map((item) => (
    <Portlet
      key={item.id}
      className="portlet carditem"
      onClick={() => setMenu(!menu)}
      onMouseLeave={() => setMenu(false)}
    >
      <div className="carditem-pre" onClick={() => null}>
        <ImgComponent
          src={item?.thumbnail_path}
          alt="images"
          className={"carditem-pre-image"}
          withIndicator={true}
        />
      </div>
      <div className="carditem-name">{item.file_name}</div>
      <div className="carditem-date">
        {moment(item.created_at, "YYYY-MM-DD").format("MM/DD/YYYY")}
      </div>

      <Dropdown className="carditem-dd">
        <Dropdown.Toggle>
          <MoreHorizIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => null}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => onDownload(item)}>
            Download
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Portlet>
  ));
}

function StandardDocList({ itemData, onDownload }) {
  const [menu, setMenu] = useState(false);

  return itemData.map((item) => (
    <Portlet
      key={item.id}
      className="portlet carditem"
      onClick={() => setMenu(!menu)}
      onMouseLeave={() => setMenu(false)}
    >
      <div className="carditem-pre" onClick={() => null}>
        <ImgComponent
          src={item?.thumbnail_path}
          alt="images"
          className={"carditem-pre-image"}
          withIndicator={true}
        />
      </div>
      <div className="carditem-name">{item.file_name}</div>
      <div className="carditem-date">
        {moment(item.created_at, "YYYY-MM-DD").format("MM/DD/YYYY")}
      </div>

      <Dropdown className="carditem-dd">
        <Dropdown.Toggle>
          <MoreHorizIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => null}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => onDownload(item)}>
            Download
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Portlet>
  ));
}

function StandardLinksList({ itemData, onDownload }) {
  return (
    <ImageList sx={{ width: 300, height: 450 }} cols={1} rowHeight={44}>
      {itemData.map((file) => {
        return <Message key={file.id} message={file} />;
        // const links = extractLinks(file.text);

        // return links.map((link, idx) => (
        //   <ImageListItem key={idx}>
        //     <FileName>
        //       <Link to={link}>{link}</Link>
        //     </FileName>{" "}
        //   </ImageListItem>
        // ));
      })}
    </ImageList>
  );
}

export const Files = ({ value, chatRoomId }) => {
  const [data, setData] = useState([]);

  const getMedia = async () => {
    const res = await getChatMedia(chatRoomId, { extention: extension[value] });
    setData(res.data.data);
  };
  const getDocs = async () => {
    const res = await getChatMedia(chatRoomId, { extention: extension[value] });
    setData(res.data.data);
  };
  const getLinks = async () => {
    const res = await getChatLinks(chatRoomId);
    setData(res.data.data);
  };

  const download = (item) => {
    getImg(item.path).then((res) => {
      const link = document.createElement("a");
      const full_name = item.file_name + "." + item.extension;
      link.href = res;
      link.target = "_blank";
      link.setAttribute("download", `${full_name}`);
      document.body.appendChild(link);
      if (link.hasAttribute("download")) {
        link.click();
        setTimeout(() => {
          link.remove();
        }, 100);
      } else {
        link.download = full_name;
        link.click();
        setTimeout(() => {
          link.remove();
        }, 100);
      }
    });
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        if (value === TYPE.MEDIA) getMedia();
        if (value === TYPE.DOCS) getDocs();
        if (value === TYPE.LINKS) getLinks();
      } catch (error) {}
    };
    if (chatRoomId && value) fetch();
  }, [value, chatRoomId]);
  return (
    <Wrapper>
      {[TYPE.MEDIA].includes(value) && (
        <StandardImageList itemData={data} onDownload={download} />
      )}
      {[TYPE.DOCS].includes(value) && (
        <StandardDocList itemData={data} onDownload={download} />
      )}
      {[TYPE.LINKS].includes(value) && <StandardLinksList itemData={data} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .image-chat {
    width: 100%;
    height: auto;
  }
  & .carditem-pre-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  & .carditem {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    margin: 15px;
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
  }
  & .carditem-dd {
    position: absolute;
    top: 0;
    right: 0;
  }
  & .carditem-dd .dropdown-toggle {
    background: transparent !important;
  }
  & .carditem-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  & .carditem-pre {
    cursor: pointer;
    height: 150px;
    width: 150px;
    margin: 5px;
    margin-bottom: 10px;
    background: #eeeeee;
    border-radius: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  & .carditem-dd .dropdown-toggle .MuiSvgIcon-root {
    fill: #646c9a !important;
  }
  & .dropdown-toggle::after {
    display: none;
  }
`;

const FileType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: #555;
  text-transform: uppercase;
  width: 35px;
  height: 22px;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  opacity: 0px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  background-color: black;
  color: white;
  cursor: pointer;
`;

const FileName = styled.p`
  margin: 0;
  color: black;
  text-transform: unset;
`;
